import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled, { css, keyframes } from "styled-components"
import { rem } from "polished"

import Link from "./link"

import SvgLogo from "../assets/images/logo.inline.svg"

const animatedLogoContainer = keyframes`
  0%,
  10% { transform: rotate(0); }

  20%,
  50% { transform: rotate(180deg); }

  60%,
  100% { transform: rotate(360deg); }
`

const animatedFrownUpsideDown = keyframes`
  0%,
  15% { transform: translateY(0); }

  21%,
  54% { transform: translateY(-120%); }

  60%,
  100% { transform: translateY(0); }
`

const Container = styled.header`
  padding: ${rem(50)} 0;
  position: relative;
  text-align: center;

  @media ${props => props.theme.mediumDown} {
    padding: ${rem(20)} 0;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 30%;
    width: 40%;
    height: 100vh;
    background: white;
    transform: translateY(-100%);
    z-index: -1;
    opacity: 0;
    transition: opacity 4s ease-in;
  }

  &::before {
    left: 0;
    box-shadow: -50px 0 50px rgba(8, 27, 31, 0.03),
      -100px 0 100px rgba(8, 27, 31, 0.03);
  }

  &::after {
    right: 0;
    box-shadow: 50px 0 50px rgba(8, 27, 31, 0.03),
      100px 0 100px rgba(8, 27, 31, 0.03);
  }

  a {
    display: inline-block;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  svg {
    width: ${rem(230)};
    height: ${rem(205)};
    display: block;
    transition: transform 4s ease-in;
    transform: rotate(5deg) scale(1.2);
    transform-origin: 50% 50%;

    @media ${props => props.theme.mediumDown} {
      width: ${rem(161)};
      height: ${rem(143)};
    }

    .-type {
      fill: ${props => props.theme.colorBlueDark};
      opacity: 0;
      transform: translateY(-10px);
      transition: all 2.5s ease-in;
      transition-delay: 1.5s;
    }

    .-logo {
      fill: none;
      stroke: ${props => props.theme.colorBlueDark};
      opacity: 0;
      stroke-width: 3.127;
      transform-box: fill-box;
      transform-origin: 50% 50%;
      transform: translateY(20px);
      transition: all 2.5s ease-in;
      animation: ${animatedLogoContainer} 20s
        ${props => props.theme.easingInOutBack};
      animation-iteration-count: infinite;

      .-smile,
      .-frown {
        transform-box: fill-box;
        transform-origin: 50% 50%;
        animation: ${animatedFrownUpsideDown} 20s
          ${props => props.theme.easingInExpo};
        animation-iteration-count: infinite;
      }
    }
  }

  ${props =>
    props.animate &&
    css`
      svg {
        transform: rotate(0) scale(1);

        .-type,
        .-logo {
          opacity: 1;
          transform: translateY(0);
        }
      }

      &::before,
      &::after {
        opacity: 1;
      }
    `}
`

const Header = ({ data, animate, ...props }) => (
  <Container animate={animate} {...props}>
    <Link to="/">
      <SvgLogo aria-label={data.site.siteMetadata.title} />
    </Link>
  </Container>
)

Header.propTypes = {
  data: PropTypes.object.isRequired,
  animate: PropTypes.bool,
}

export default props => (
  <StaticQuery
    query={graphql`
      query Header2Query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
